import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Modal,
  Button,
  TextField,
  styled,
} from "@mui/material";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Fill 1.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { Form } from "react-bootstrap";

interface TripStatus {
  Completed: "success";
  Ongoing: "warning";
  Upcoming: "info";
}

interface Trip {
  id: number;
  userName: string;
  pickupLocation: string;
  destination: string;
  pickupTime: string;
  status: keyof TripStatus;
  phoneNo: string;
  // Additional fields for modal
  driverName?: string;
  dropTime?: string;
  userVehicleType?: string;
  licensePlateNumber?: string;
}

const trips: Trip[] = [
  {
    id: 1,
    userName: "Baraka M.Jaa",
    pickupLocation: "Pickup Location longer text here...",
    destination: "Destination location longer text here...",
    pickupTime: "29-11-2024 09:59 AM",
    status: "Completed",
    phoneNo: "+255 754 805 256",
  },
  {
    id: 2,
    userName: "John",
    pickupLocation: "Pickup Location",
    destination: "Destination location",
    pickupTime: "29-11-2024 09:59 AM",
    status: "Ongoing",
    phoneNo: "+255 754 805 256",
  },
  {
    id: 3,
    userName: "Baraka M.Jaa",
    pickupLocation: "Pickup Location longer text here...",
    destination: "Destination location longer text here...",
    pickupTime: "29-11-2024 09:59 AM",
    status: "Completed",
    phoneNo: "+255 754 805 256",
  },
  
  {
    id: 4,
    userName: "Salma Godwin",
    pickupLocation: "Pickup Location",
    destination: "Destination location",
    pickupTime: "--",
    status: "Upcoming",
    phoneNo: "+255 754 805 256",
  },
  {
    id: 5,
    userName: "Baraka M.Jaa",
    pickupLocation: "Pickup Location longer text here...",
    destination: "Destination location longer text here...",
    pickupTime: "29-11-2024 09:59 AM",
    status: "Completed",
    phoneNo: "+255 754 805 256",
  },
  {
    id: 6,
    userName: "Salma Godwin",
    pickupLocation: "Pickup Location",
    destination: "Destination location",
    pickupTime: "--",
    status: "Upcoming",
    phoneNo: "+255 754 805 256",
  },
  {
    id: 7,
    userName: "Salma Godwin",
    pickupLocation: "Pickup Location",
    destination: "Destination location",
    pickupTime: "--",
    status: "Upcoming",
    phoneNo: "+255 754 805 256",
  },
  {
    id: 8,
    userName: "Salma Godwin",
    pickupLocation: "Pickup Location",
    destination: "Destination location",
    pickupTime: "--",
    status: "Upcoming",
    phoneNo: "+255 754 805 256",
  },
  {
    id: 9,
    userName: "Baraka M.Jaa",
    pickupLocation: "Pickup Location longer text here...",
    destination: "Destination location longer text here...",
    pickupTime: "29-11-2024 09:59 AM",
    status: "Completed",
    phoneNo: "+255 754 805 256",
  },
  // Add more trip data here...
];

export default function TripDetails() {
  const [searchQuery, setSearchQuery] = useState("");
  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);

  const handleRowClick = (trip: Trip) => {
    setSelectedTrip(trip);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTrip(null);
  };

  const getStatusColor = (status: keyof TripStatus) => {
    const colors = {
      Completed: "#1FA541",
      Ongoing: "#DB5F04",
      Upcoming: "#DB5F04",
    };
    return colors[status];
  };
  const ModalContent = styled(Box)({
    backgroundColor: "white",
    borderRadius: "15px",
    padding: "40px",
    maxWidth: "750px",
    width: "800px",
    maxHeight: "97vh",
    "&:focus": {
      outline: "none",
    },
  });

  const InputLabel = styled(Typography)({
    marginBottom: "1px",
    fontWeight: 600,
    fontcolor: "#344054",
    fontFamily: "Montserrat",
    color: "#2C3E50",
  });

  const filteredTrips = trips.filter(
    (trip) =>
      trip.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      trip.phoneNo.includes(searchQuery)
  );
  const StyledModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "237px",
        marginTop: "39px",
        fontFamily: "Montserrat",
      }}
    >
      <Box sx={{ p: 3, maxWidth: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 3,
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat", fontWeight: "600" }}
          >
            Trip Details
          </Typography>

          <div className="relative ">
            <SearchIcon
              className="absolute left-4 top-1/2 transform -translate-y-1/2"
              style={{
                width: "20px",
                height: "20px",
                color: "#FDA214",
                fontFamily: "Montserrat",
              }} // Adjust size and color if necessary
            />
            <Form.Control
              type="text"
              placeholder="Search by User name/driver name"
              className="pl-10 pr-6 py-2 w-112 rounded-full border border-gray-300"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: "30rem",
                fontFamily: "Montserrat",
                height: "3.5rem",
                lineHeight: "3rem",
                borderRadius: "20px",
                // textAlign: "center",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }} // Custom width and height
            />
            <style>
              {`
                  .relative input::placeholder {
                  color: #B4BDC4; // Placeholder color
                  }
                 `}
            </style>
          </div>
        </Box>

        <TableContainer
          component={Paper}
          elevation={0}
          style={{ fontFamily: "Montserrat" }}
        >
          <Table>
            <TableHead
              sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
            >
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  User Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  Pickup Location
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  Destination
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  Pickup Time
                  <KeyboardArrowDownIcon
                    sx={{ verticalAlign: "middle", ml: 1 }}
                  />
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", py: 1, fontFamily: "Montserrat" }}
                >
                  Ph. No.
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTrips.map((trip, index) => (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(trip)}
                  style={{
                    fontFamily: "Montserrat",
                    cursor: "pointer",
                    backgroundColor:
                      hoveredRow === trip.id ? "#f0f0f0" : "transparent",
                  }}
                  onMouseEnter={() => setHoveredRow(trip.id)}
                  onMouseLeave={() => setHoveredRow(null)}
                >
                  <TableCell
                    sx={{ color: "#2B3674", py: 2, fontFamily: "Montserrat" }}
                  >
                    {trip.userName}
                  </TableCell>
                  <TableCell sx={{ py: 2, fontFamily: "Montserrat" }}>
                    {trip.pickupLocation}
                  </TableCell>
                  <TableCell sx={{ py: 2, fontFamily: "Montserrat" }}>
                    {trip.destination}
                  </TableCell>
                  <TableCell sx={{ py: 2, fontFamily: "Montserrat", fontWeight: 400 }}>
                    {trip.pickupTime}
                  </TableCell>
                  <TableCell sx={{ py: 2, fontFamily: "Montserrat" }}>
                    <Box
                      component="span"
                      sx={{
                        color: getStatusColor(trip.status),
                        fontWeight: 700,
                      }}
                    >
                      {trip.status}
                    </Box>
                  </TableCell>
                  <TableCell sx={{ py: 2, fontFamily: "Montserrat" }}>
                    <Typography
                      component="a"
                      href={`tel:${trip.phoneNo}`}
                      sx={{
                        fontWeight: 600,
                        color: "#93310D",
                        textDecoration: "underline",
                        "&:hover": {
                          color: "#93310D",
                        },
                      }}
                    >
                      {trip.phoneNo}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <StyledModal open={modalOpen} onClose={handleCloseModal}>
        <ModalContent>
          <Typography
            variant="h5"
            sx={{ mb: 4, fontFamily: "Montserrat", fontWeight: 600 }}
          >
            Trip Details
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <Box sx={{width: "650px",}}>
              <InputLabel>Pickup Location</InputLabel>
              <TextField
                fullWidth
                value={selectedTrip?.pickupLocation || ""}
                InputProps={{
                  readOnly: true,
                  sx: {
                    fontFamily: "Montserrat",
                    height: "40px",
                    borderRadius: "8px",
                  },
                }}
                sx={{
                  borderRadius: "40px",
                }}
              />
            </Box>

            <Box sx={{width: "650px",}}>
              <InputLabel>Drop Location</InputLabel>
              <TextField
                fullWidth
                value={selectedTrip?.destination || ""}
                InputProps={{
                  readOnly: true,
                  sx: {
                    fontFamily: "Montserrat",
                    height: "40px",
                    borderRadius: "8px",
                  },
                }}
              />
            </Box>

            <Box
              sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}
            >
              <Box
              sx={{width: "300px",}}
              >
                <InputLabel>User Name</InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.userName || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
              <Box sx={{width: "300px",}}>
                <InputLabel>Driver Name</InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.driverName || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}
            >
              <Box sx={{width: "300px"}}>
                <InputLabel>Pickup Time</InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.pickupTime || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
              <Box sx={{width: "300px",}}>
                <InputLabel>Drop Time</InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.dropTime || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}
            >
              <Box sx={{width: "300px",}}>
                <InputLabel>User Vehicle Type</InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.userVehicleType || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
              <Box sx={{width: "300px",}}>
                <InputLabel>License Plate Number (User)</InputLabel>
                <TextField
                  fullWidth
                  value={selectedTrip?.licensePlateNumber || ""}
                  InputProps={{
                    readOnly: true,
                    sx: {
                      fontFamily: "Montserrat",
                      height: "40px",
                      borderRadius: "8px",
                    },
                  }}
                />
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                onClick={handleCloseModal}
                variant="contained"
                sx={{
                  bgcolor: "#FF8A00",
                  borderRadius: "6px",
                  px: 4,
                  py: 1,
                  fontFamily: "Montserrat",
                  "&:hover": {
                    bgcolor: "#e67a00",
                  },
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </ModalContent>
      </StyledModal>
    </div>
  );
}
