import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from '../../context/AuthContext'; // Import the useAuth hook
import { ReactComponent as DashboardIcon } from "../../assets/icons/Deshboard.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/ic_outline-logout.svg";
import { ReactComponent as UploadIcon } from "../../assets/icons/uploadSidebar.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/heroicons_users.svg";
import { ReactComponent as DriversIcon } from "../../assets/icons/clarity_employee-line.svg";
import { ReactComponent as SubscriptionsIcon } from "../../assets/icons/ph_coin.svg";
import { ReactComponent as SupportTicketsIcon } from "../../assets/icons/solar_help-broken.svg";
import { ReactComponent as DriverPayoutsIcon } from "../../assets/icons/Frame.svg";
import { ReactComponent as ContactDetailsIcon } from "../../assets/icons/mage_id-card.svg";
import { ReactComponent as TripIcon } from "../../assets/icons/trip.svg";

const Sidebar: React.FC = () => {
  const [activeItem, setActiveItem] = useState(""); // State to track active item
  const { logout } = useAuth(); // Use the logout function from AuthContext

  const handleSetActive = (item: string) => {
    setActiveItem(item);
  };

  const handleLogout = () => {
    // Call the logout method from AuthContext
    logout();
  };

  return (
    <div className="fixed w-[254px] h-[calc(100vh-20px)] left-0 top-[60px]  bg-[#2C3E50] flex flex-col justify-between pl-0 pr-[20px] pt-[20px] pb-[20px] text-white">
      <div className="mb-0 text-xl font-bold cursor-pointer">
        {/* NavLink for Dashboard */}
        <NavLink
          to="dashboard"
          className={({ isActive }) =>
            `flex items-center py-4 border-none shadow-none transition-all duration-300 ${
              isActive ? "bg-[#FDA214]" : ""
            }`
          }
          style={{
            padding: "40px",
            width: "108.8%",
            height : "60px",
            outline: "none",
            textDecoration: "none",
            border: "none",
            boxShadow: "none",
          }}
          onClick={() => handleSetActive("dashboard")}
        >
          {({ isActive }) => (
            <>
              <DashboardIcon
                className={`w-[18px] h-[18px] mr-[20px] ${
                  isActive ? "filter brightness-0 invert" : "text-orange-400"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  isActive ? "text-white" : "text-[#FDA214]"
                }`}
              >
                DASHBOARD
              </span>
            </>
          )}
        </NavLink>
        {/* NavLink for Users */}
        <NavLink
          to="users"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive ? "bg-[#FDA214]" : ""
            }`
          }
          style={{
            padding: "40px",
            width: "108.8%",
            height : "60px",
            outline: "none",
            textDecoration: "none",
          }}
          onClick={() => handleSetActive("users")}
        >
          {({ isActive }) => (
            <>
              <UserIcon
               
                
                className={`w-[18px] h-[18px] mr-[20px] ${
                  isActive ? "filter brightness-0 invert" : "text-orange-400"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  isActive ? "text-white" : "text-[#FDA214]"
                }`}
              >
                USERS
              </span>
            </>
          )}
        </NavLink>

        {/* NavLink for Drivers */}
        <NavLink
          to="drivers"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive ? "bg-[#FDA214]" : ""
            }`
          }
          style={{
            padding: "40px",
            width: "108.8%",
            height : "60px",
            outline: "none",
            textDecoration: "none",
          }}
          onClick={() => handleSetActive("drivers")}
        >
          {({ isActive }) => (
            <>
              <DriversIcon
                className={`w-[18px] h-[18px] mr-[20px] ${
                  isActive ? "filter brightness-0 invert" : "text-orange-400"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  isActive ? "text-white" : "text-[#FDA214]"
                }`}
              >
                DRIVERS
              </span>
            </>
          )}
        </NavLink>

        {/* NavLink for Subscriptions */}
        <NavLink
          to="subscriptions"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive ? "bg-[#FDA214]" : ""
            }`
          }
          style={{
            padding: "40px ",
            width: "108.8%",
            height : "60px",
            outline: "none",
            textDecoration: "none",
          }}
          onClick={() => handleSetActive("subscriptions")}
        >
          {({ isActive }) => (
            <>
              <SubscriptionsIcon
                className={`w-[18px] h-[18px] mr-[20px] ${
                  isActive ? "filter brightness-0 invert" : "text-orange-400"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  isActive ? "text-white" : "text-[#FDA214]"
                }`}
              >
                SUBSCRIPTIONS
              </span>
            </>
          )}
        </NavLink>

        {/* NavLink for Support Tickets */}
        <NavLink
          to="support-tickets"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive ? "bg-[#FDA214]" : ""
            }`
          }
          style={{
            padding: "40px ",
            width: "108.8%",
            height : "60px",
            outline: "none",
            textDecoration: "none",
          }}
          onClick={() => handleSetActive("support-tickets")}
        >
          {({ isActive }) => (
            <>
              <SupportTicketsIcon
                className={`w-[18px] h-[18px] mr-[20px] ${
                  isActive ? "filter brightness-0 invert" : "text-orange-400"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  isActive ? "text-white" : "text-[#FDA214]"
                }`}
              >
                SUPPORT TICKETS
              </span>
            </>
          )}
        </NavLink>

        {/* NavLink for Driver Payouts */}
        <NavLink
          to="driver-payouts"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive ? "bg-[#FDA214]" : ""
            }`
          }
          style={{
            padding: "40px ",
            width: "108.8%",
            height : "60px",
            outline: "none",
            textDecoration: "none",
          }}
          onClick={() => handleSetActive("driver-payouts")}
        >
          {({ isActive }) => (
            <>
              <DriverPayoutsIcon
                className={`w-[18px] h-[18px] mr-[20px] ${
                  isActive ? "filter brightness-0 invert" : "text-orange-400"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  isActive ? "text-white" : "text-[#FDA214]"
                }`}
              >
                DRIVER PAYOUTS
              </span>
            </>
          )}
        </NavLink>

        <NavLink
          to="trip-details"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive ? "bg-[#FDA214]" : ""
            }`
          }
          style={{
            padding: "40px ",
            width: "108.8%",
            height : "60px",
            outline: "none",
            textDecoration: "none",
          }}
          onClick={() => handleSetActive("trip-details")}
        >
          {({ isActive }) => (
            <>
              <TripIcon
                className={`w-[18px] h-[18px] mr-[20px] ${
                  isActive ? "filter brightness-0 invert" : "text-orange-400"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  isActive ? "text-white" : "text-[#FDA214]"
                }`}
              >
                TRIP DETAiLS
              </span>
            </>
          )}
        </NavLink>

        {/* NavLink for Contact Details */}
        <NavLink
          to="contact-details"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive ? "bg-[#FDA214]" : ""
            }`
          }
          style={{
            padding: "40px ",
            width: "108.8%",
            outline: "none",
            height : "60px",
            textDecoration: "none",
          }}
          onClick={() => handleSetActive("contact-details")}
        >
          {({ isActive }) => (
            <>
              <ContactDetailsIcon
                className={`w-[18px] h-[18px] mr-[20px] ${
                  isActive ? "filter brightness-0 invert" : "text-orange-400"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  isActive ? "text-white" : "text-[#FDA214]"
                }`}
              >
                CONTACT DETAILS
              </span>
            </>
          )}
        </NavLink>
        {/* Navlink for Upload */}
        <NavLink
          to="Image-Upload"
          className={({ isActive }) =>
            `flex items-center py-4 transition-all duration-300 ${
              isActive ? "bg-[#FDA214]" : ""
            }`
          }
          style={{
            padding: "40px ",
            width: "108.8%",
            height : "60px",
            outline: "none",
            textDecoration: "none",
          }}
          onClick={() => handleSetActive("Image-Upload")}
        >
          {({ isActive }) => (
            <>
              <UploadIcon
                className={`w-[18px] h-[18px] mr-[20px] ${
                  isActive ? "filter brightness-0 invert" : "text-orange-400"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  isActive ? "text-white" : "text-[#FDA214]"
                }`}
              >
                IMAGE UPLOAD
              </span>
            </>
          )}
        </NavLink>
      </div>

      <div className="px-4 py-4">
        <button
          className="flex items-center justify-center w-full bg-red-600 text-white py-2 px-4 rounded"
          onClick={handleLogout}
        >
          <LogoutIcon  className="mr-4 w-5 h-5" />
          <span>Log Out</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
