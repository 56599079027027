import React from "react";
import { ReactComponent as CustomLeftArrow } from "../../../../assets/icons/arrow-circle-left.svg";
import { ReactComponent as CustomMapIcon } from "../../../../assets/icons/Location.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/solar_phone-rounded-broken.svg";
import { ReactComponent as VehicleIcon } from "../../../../assets/icons/ph_car-profile.svg";
import { ReactComponent as LicensePlateIcon } from "../../../../assets/icons/vaadin_password.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/solar_letter-broken.svg";
import { ReactComponent as Pdf } from "../../../../assets/icons/Pdf.svg";
import { ReactComponent as Defaulticons } from "../../../../assets/icons/defaultImg.svg";
import { Form } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import axios from "axios";
import DatabaseApi from "../../../../services/database_api";
import RippleButton from "../../../../RippleButton/RippleButton";

interface Driver {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password?: string;
  account_type?: string;
  company_name?: string;
  vehicle_type?: string;
  vehicle_color?: string;
  license_plate_number?: string;
  license_document?: string | null;
  towing_license?: string | null;
  insurance_document?: string | null;
  upload_company_document?: string | null;
  upload_vehicle_registration?: string | null;
  profile_image?: string | null;
  account_status?: string;
  created_at: string;
  updated_at: string;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

const stats = [
  { label: "Towing Trips", value: 300 },
  { label: "Total Earned", value: "$ 50,000" },
  { label: "Average Rating", value: 3.5 },
  { label: "Reviews Received", value: 40 },
];

const getDriverDocuments = (driver: Driver) => [
  {
    name: "Driving License",
    file: DatabaseApi.getDriverDoc(driver.license_document || "null") || null,
  },
  {
    name: "Towing License",
    file: DatabaseApi.getDriverDoc(driver.towing_license || "null") || null,
  },
  {
    name: "Insurance Document",
    file: DatabaseApi.getDriverDoc(driver.insurance_document || "null") || null,
  },
  {
    name: "Company Document",
    file:
      DatabaseApi.getDriverDoc(driver.upload_company_document || "null") ||
      null,
  },
  {
    name: "Vehicle Registration",
    file:
      DatabaseApi.getDriverDoc(driver.upload_vehicle_registration || "null") ||
      null,
  },
];
const data = [
  { month: "Jan", Journeys: 5 },
  { month: "Feb", Journeys: 10 },
  { month: "Mar", Journeys: 8 },
  { month: "Apr", Journeys: 15 },
  { month: "May", Journeys: 13 },
  { month: "Jun", Journeys: 12 },
  { month: "Jul", Journeys: 12 },
  { month: "Aug", Journeys: 14 },
  { month: "Sep", Journeys: 18 },
  { month: "Oct", Journeys: 20 },
  { month: "Nov", Journeys: 0 },
  { month: "Dec", Journeys: 0 },
];

interface Review {
  text: string;
}

const reviews = [
  {
    rating: "*******",
    text: "Marcus was a lifesaver! Quick response, friendly service. Highly recommend!",
  },
  {
    rating: "*******",
    text: "Marcus arrived promptly and handled my car with care. Great job!",
  },
  {
    rating: "*******",
    text: "I'm so grateful for Marcus's help. He made a stressful situation much easier.",
  },
  {
    rating: "*******",
    text: "Marcus was professional, efficient, and a pleasure to deal with. A+ service.",
  },
  {
    rating: "*******",
    text: "Thanks, Marcus, for your fast and reliable towing service. You're a star!",
  },
];

const renderAttachment = (attachment: string | null) => {
  if (!attachment) return <Defaulticons className="w-20 h-20 rounded-full" />;

  const fullPath = DatabaseApi.getDriverProfile(attachment);

  if (fullPath.endsWith(".txt")) {
    return (
      <a href={fullPath} target="_blank" rel="noopener noreferrer">
        View Text File
      </a>
    );
  } else {
    return (
      <img
        src={fullPath}
        alt="Profile"
        className="w-20 h-20 rounded-full"
        style={{ maxWidth: "100px", maxHeight: "100px", objectFit: "cover" }}
      />
    );
  }
};

interface LocationWidgetProps {
  address: string;
  isPickup: boolean;
}
const LocationWidget: React.FC<LocationWidgetProps> = ({
  address,
  isPickup,
}) => (
  <Box
    sx={{
      padding: 2,
      backgroundColor: "#E8ECF4",
      borderRadius: 3,
      display: "flex",
      alignItems: "center",
      position: "relative",
      fontFamily: "Montserrat",
    }}
  >
    <Box
      sx={{
        width: 24,
        height: 24,
        borderRadius: "50%",
        backgroundColor: isPickup ? "#FF6B00" : "#8B3DFF",
        border: "6px solid white",
        flexShrink: 0,
        position: "relative",
        zIndex: 2,
        fontFamily: "Montserrat",
      }}
    />
    <Typography
      sx={{
        marginLeft: 2,
        fontSize: 16,
        color: "#0E2E5D",
        lineHeight: 1.5,
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        fontFamily: "Montserrat",
      }}
    >
      {address}
    </Typography>
  </Box>
);

interface ConnectedLocationWidgetsProps {
  pickupAddress: string;
  dropoffAddress: string;
}
const ConnectedLocationWidgets: React.FC<ConnectedLocationWidgetsProps> = ({
  pickupAddress,
  dropoffAddress,
}) => {
  return (
    <Box sx={{ position: "relative", fontFamily: "Montserrat" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          fontFamily: "Montserrat",
        }}
      >
        <LocationWidget address={pickupAddress} isPickup={true} />
        <LocationWidget address={dropoffAddress} isPickup={false} />
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 26,
          top: 40,
          bottom: 40,
          width: 5,
          background: "linear-gradient(to bottom, #FF6B00, #FB25FF, #8B3DFF)",
          zIndex: 1,
        }}
      />
    </Box>
  );
};

interface TripInfo {
  name: string;
  timestamp: string;
  vehicle: string;
  distance: string;
  duration: string;
  pickupLocation: string;
  dropoffLocation: string;
  notes: string;
  address: string;
  isPickup: boolean;
}

const TripCard = ({ trip }: { trip: TripInfo }) => (
  <Box
    sx={{
      maxWidth: 345,
      width: "100%",
      height: "100%",
      bgcolor: "background.paper",
      borderRadius: 2,
      boxShadow: 1,
      overflow: "hidden",
    }}
  >
    <Box sx={{ p: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          fontFamily: "Montserrat",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          color="#122E59"
          style={{ fontFamily: "Montserrat" ,fontWeight: "bold" }}
        >
          {trip.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          style={{ fontFamily: "Montserrat" }}
        >
          {trip.timestamp}
        </Typography>
      </Box>
      <Typography
        variant="body2"
        gutterBottom
        style={{ fontFamily: "Montserrat" }}
      >
        Vehicle: {trip.vehicle}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          fontFamily: "Montserrat",
        }}
      >
        <Typography variant="body2" style={{ fontFamily: "Montserrat" }}>
          Distance: {trip.distance}
        </Typography>
        <Typography variant="body2" style={{ fontFamily: "Montserrat" }}>
          Duration: {trip.duration}
        </Typography>
      </Box>
      <ConnectedLocationWidgets
        pickupAddress={trip.pickupLocation}
        dropoffAddress={trip.dropoffLocation}
      />
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mt: 2, fontFamily: "Montserrat" }}
      >
        Notes for driver: {trip.notes}
      </Typography>
      {/* <Box
        component="button"
        sx={{
          mt: 2,
          width: "100%",
          py: 1.5,
          px: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "transparent",
          border: "2px solid #FF7A00", // Orange border color to match Figma design
          borderRadius: "25px", // Makes the button fully rounded
          color: "#FF7A00", // Orange text color
          fontWeight: "bold",
          fontSize: "1rem",
          cursor: "pointer",
          fontFamily: "Montserrat",
          transition: "all 0.3s ease",
          "&:hover": {
            backgroundColor: "#FDA214",
            color: "white",
            "& img": {
              filter: "brightness(0) invert(1)", // This makes the image appear white
            },
          },
        }}
      >
        <Box
          component="span"
          sx={{
            mr: 1,
            display: "flex",
            alignItems: "center",
            fontFamily: "Montserrat",
          }}
        >
          <CustomMapIcon width={24} height={24} />
        </Box>
        View Trip on Map
      </Box> */}
    </Box>
  </Box>
);

const trips: TripInfo[] = Array(6).fill({
  name: "Sarah",
  timestamp: "24-09-2024 4:35 PM",
  vehicle: "Toyota Yaris, 5914 AB",
  distance: "108 KM",
  duration: "2h 30m",
  pickupLocation:
    "Pickup Location full address exact address long, picked from google map",
  dropoffLocation:
    "Dropoff Location full address exact address long, picked from google map",
  notes: "Please bring extra long hooks, chains and some water",
});

export default function PendingDriver() {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const driver = location.state?.driver as Driver;

  if (!driver) {
    return <div>No driver data available</div>;
  }

  const handleReject = () => {
    setShowRemoveModal(true);
    handleCloseModal(); // Close the details modal
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleCloseRemoveModal = () => {
    setShowRemoveModal(false);
  };
  // handleDeleteAndBlock
  const handleDeleteAndBlock = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.put(
        DatabaseApi.updateAccountStatusUrl(),
        {
          driver_id: driver.id.toString(),
          account_status: "Deactivate",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Driver blocked successfully");
        // Remove the driver from the list and navigate back
        navigate("/admin/drivers", { state: { removedDriverId: driver.id } });
      } else {
        console.error("Failed to block driver");
      }
    } catch (error) {
      console.error("Error blocking driver:", error);
    }
    setShowRemoveModal(false);
  };
  // handleDelete
  const handleDelete = async () => {
    const token = localStorage.getItem("authToken");
    try {
      const response = await axios.delete(
        DatabaseApi.deleteDriverUrl(driver.id),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        console.log("Driver deleted successfully");
        navigate("/admin/drivers", { state: { removedDriverId: driver.id } });
      } else {
        console.error("Failed to delete driver");
      }
    } catch (error) {
      console.error("Error deleting driver:", error);
    }
    setShowRemoveModal(false);
  };

  // pdf view
  const driverDocuments = getDriverDocuments(driver);

  const handleDocumentClick = (fileUrl: string | null) => {
    if (fileUrl) {
      // If the URL is relative, prepend your API base URL
      const fullUrl = fileUrl.startsWith("http")
        ? fileUrl
        : `${process.env.REACT_APP_API_URL}${fileUrl}`;

      // Determine file type
      const fileName = fileUrl.split("/").pop() || "document";
      const fileExtension = fileName.split(".").pop()?.toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

      if (imageExtensions.includes(fileExtension || "")) {
        setSelectedFile(fullUrl);
      } else if (fileExtension === "pdf") {
        window.open(fullUrl, "_blank");
      }
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  return (
    <Box className="bg-white p-8 md:p-20 min-h-screen lg:ml-48 ">
      <div className="p-4 md:p-8 max-w-[100vw] overflow-x-hidden">
        <div className="max-w-7xl mx-auto">
          <Link
            to="/admin/drivers/"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CustomLeftArrow
              style={{
                width: 45,
                height: 55,
                marginRight: 10,
                marginBottom: 10,
              }}
            />
            <Typography
              variant="h5"
              gutterBottom
              color="#2C3E50"
              style={{ fontFamily: "Montserrat",  fontWeight: "600" }}
            >
              Driver Details
            </Typography>
            <div className="mt-8 flex justify-end space-x-4"></div>
          </Link>
          <div className="flex items-center mb-6 gap-3">
            {renderAttachment(driver.profile_image ?? null)}
            <h2
              className="text-2xl font-semibold"
              style={{ fontFamily: "Montserrat" }}
            >
              {`${driver.first_name} ${driver.last_name}`}
            </h2>

            <div className="ml-auto flex space-x-4">
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#961F16",
                  color: "white",
                  fontFamily: "Montserrat",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#7a1612", // Optional: darken the color on hover
                  },
                }}
                onClick={handleReject}
              >
                Remove Driver
              </Button>
            </div>
          </div>

          <Box sx={{ width: "100%", maxWidth: "100%", overflow: "hidden" }}>
            <Grid
              container
              spacing={4}
              sx={{
                width: "103.5%",
                margin: "3",
                padding: "16px 0",
                py: 4,
                fontFamily: "Montserrat",
              }}
            >
              {stats.map((stat, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Paper
                    sx={{
                      p: 3,
                      textAlign: "left", // Aligns content to the left within the Paper
                      backgroundColor: "#f4f8fb",
                      borderRadius: 2,
                      boxShadow: "none", // Removes shadow
                      border: "none", // Ensures no border
                      fontFamily: "Montserrat",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="#345C72"
                      fontWeight="medium"
                      style={{ fontFamily: "Montserrat" }}
                    >
                      {stat.label}
                    </Typography>
                    <Typography
                      variant="h6"
                      color="#345C72"
                      fontWeight="bold"
                      component="div"
                      style={{ fontFamily: "Montserrat" }}
                    >
                      {stat.value}
                    </Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Box>

          <div className="grid grid-cols-3 gap-6 mb-6">
            {/* First Row */}
            <Form.Group className="mb-4 relative">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Phone Number
              </Form.Label>
              <PhoneIcon className="absolute left-2 bottom-2.5 w-7 h-5" />
              <Form.Control
                type="text"
                value={driver.phone}
                readOnly
                style={{ paddingLeft: "2.5rem", fontFamily: "Montserrat" }}
                className="mt-1"
              />
            </Form.Group>
            <Form.Group className="mb-4 relative custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Email
              </Form.Label>
              <EmailIcon className="absolute left-2 bottom-2.5 w-7 h-5" />
              <Form.Control
                type="email"
                value={driver.email}
                readOnly
                style={{ paddingLeft: "2.5rem", fontFamily: "Montserrat" }}
                className="mt-1"
              />
            </Form.Group>
            <Form.Group className="mb-4 relative custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Vehicle Type
              </Form.Label>
              <VehicleIcon className="absolute left-2 bottom-2.5 w-7 h-5" />
              <Form.Control
                type="text"
                value={driver.vehicle_type || "N/A"}
                readOnly
                style={{ paddingLeft: "2.5rem", fontFamily: "Montserrat" }}
                className="mt-1"
              />
            </Form.Group>

            {/* Second Row */}
            <Form.Group className="mb-4 relative custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                License Plate Number
              </Form.Label>
              <LicensePlateIcon className="absolute left-2 bottom-2.5 w-7 h-5" />
              <Form.Control
                type="text"
                value={driver.license_plate_number || "N/A"}
                readOnly
                style={{ paddingLeft: "2.5rem", fontFamily: "Montserrat" }}
                className="mt-1"
              />
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Account Type
              </Form.Label>
              <Form.Control
                type="text"
                value={driver.account_type || "N/A"}
                readOnly
                className="mt-1"
                style={{ fontFamily: "Montserrat" }}
              />
            </Form.Group>
            <Form.Group className="custom-form-group">
              <Form.Label
                style={{
                  marginBottom: "0.0rem",
                  position: "relative",
                  top: "0.3rem",
                  fontFamily: "Montserrat",
                }}
              >
                Company Name
              </Form.Label>
              <Form.Control
                type="text"
                value={driver.company_name || "N/A"}
                readOnly
                className="mt-1"
                style={{ fontFamily: "Montserrat" }}
              />
            </Form.Group>
          </div>

          <div className="mt-6 space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {driverDocuments.map((doc, index) => (
                <div key={index} className="flex flex-col items-start w-full">
                  <Form.Group className="w-full">
                    <Form.Label
                      className="font-medium"
                      style={{
                        marginBottom: "0.0rem",
                        position: "relative",
                        top: "0.1rem",
                      }}
                    >
                      {doc.name}
                    </Form.Label>
                    <div
                      className="flex items-center border p-4 rounded cursor-pointer w-full bg-gray-100 hover:bg-gray-200 select-none"
                      style={{ height: "80px" }}
                      onClick={() => handleDocumentClick(doc.file)}
                    >
                      {doc.file ? (
                        <>
                          <Pdf className="w-10 h-10 mr-3" />
                          <span className="text-sm">{doc.name}</span>
                        </>
                      ) : (
                        <>
                          <Pdf className="w-10 h-10 mr-3 text-gray-400" />
                          <p className="text-sm text-gray-400">
                            No document uploaded
                          </p>
                        </>
                      )}
                    </div>
                  </Form.Group>
                </div>
              ))}
            </div>

            {selectedFile && (
              <div
                className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
                onClick={clearSelectedFile}
              >
                <div
                  className="max-w-4xl max-h-[80vh] bg-white p-4 rounded-lg"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="flex justify-end mb-2">
                    <button
                      onClick={clearSelectedFile}
                      className="text-red-500 hover:text-red-700"
                    >
                      Close
                    </button>
                  </div>
                  <img
                    src={selectedFile}
                    alt="Document Preview"
                    className="max-w-full max-h-[70vh] object-contain mx-auto"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="p-6 mb-6 border rounded-lg mt-10">
            <Typography
              variant="h5"
              gutterBottom
              color="#E5E5EA"
              textAlign="center"
              style={{ fontFamily: "Montserrat" }}
            >
              Journeys - 2024
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                style={{ fontFamily: "Montserrat" }}
                data={data}
                margin={{ top: 20, right: 30, left: 30, bottom: 0 }}
              >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="month" style={{ fontFamily: "Montserrat" }} />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="Journeys"
                  stroke="#F39C12"
                  strokeWidth={2}
                  dot={{ r: 4 }}
                  style={{ fontFamily: "Montserrat" }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <Box
            sx={{
              maxWidth: "1200px",
              mx: "auto",
              p: 4,
              borderRadius: 10,
              fontFamily: "Montserrat",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 12,
                justifyContent: "space-between",
                fontFamily: "Montserrat",
              }}
            >
              {/* Left Column */}
              <Box
                sx={{
                  flex: 5,
                  border: "1px solid #e0e0e0",
                  borderRadius: 2,
                  padding: 2,
                  margin: -4,
                  fontFamily: "Montserrat",
                }}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  align="center"
                  gutterBottom
                  style={{ color: "#8E8E93", fontFamily: "Montserrat" }}
                >
                  Reviews
                </Typography>
                {reviews.map((review, index) => (
                  <Card
                    key={index}
                    sx={{
                      mb: 2,
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                      fontFamily: "Montserrat",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="body2"
                        style={{ color: "#0E2E5D", fontFamily: "Montserrat" }}
                      >
                        <div>{review.rating}</div>
                        <p>{review.text}</p>
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>

              {/* Right Column */}

              <Box
                sx={{
                  flex: 5,
                  border: "1px solid #e0e0e0",
                  borderRadius: 2,
                  padding: 2,
                  margin: -4,
                  fontFamily: "Montserrat",
                }}
              >
                <Typography
                  variant="h5"
                  component="h2"
                  align="center"
                  gutterBottom
                  style={{ color: "#8E8E93", fontFamily: "Montserrat" }}
                >
                  Reviews
                </Typography>
                {reviews.map((review, index) => (
                  <Card
                    key={index}
                    sx={{
                      mb: 2,
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                      fontFamily: "Montserrat",
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="body2"
                        style={{ color: "#0E2E5D", fontFamily: "Montserrat" }}
                      >
                        <div>{review.rating}</div>
                        <p>{review.text}</p>
                      </Typography>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: 1300,
              margin: "auto",
              p: -4,
              marginTop: 5,
              fontFamily: "Montserrat",
            }}
          >
            <Grid
              container
              spacing={5}
              sx={{ fontFamily: "Montserrat", marginLeft: -2.5 }}
            >
              {trips.map((trip, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                  style={{ fontFamily: "Montserrat" }}
                >
                  <TripCard trip={trip} />
                </Grid>
              ))}
            </Grid>
          </Box>

          <Dialog
            open={showRemoveModal}
            onClose={handleCloseRemoveModal}
            aria-labelledby="remove-driver-dialog-title"
            PaperProps={{
              style: {
                borderRadius: "14px",
                padding: "13px",
                maxWidth: "600px",
                fontFamily: "Montserrat",
              },
            }}
          >
            {/* Close Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                fontFamily: "Montserrat",
              }}
            >
              <IconButton
                aria-label="close"
                onClick={handleCloseRemoveModal}
                sx={{
                  minWidth: "auto",
                  color: "#FDA214",
                  fontWeight: "bold",
                  "&:hover": {
                    bgcolor: "#FB923C",
                    color: "white",
                    fontFamily: "Montserrat",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Modal Title */}
            <DialogTitle
              id="remove-driver-dialog-title"
              style={{
                fontWeight: "bold",
                color: "#122E59",
                textAlign: "center",
                paddingBottom: "8px",
                fontFamily: "Montserrat",
              }}
            >
              Remove Driver
            </DialogTitle>

            {/* Modal Content */}
            <DialogContent>
              <Typography
                style={{
                  textAlign: "center",
                  color: "#122E59",
                  marginBottom: "24px",
                  fontFamily: "Montserrat",
                }}
              >
                Are you sure you want to delete this Driver account?
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "#122E59",
                  marginBottom: "16px",
                  fontSize: "18px",
                  lineHeight: "1.5",
                  fontFamily: "Montserrat",
                }}
              >
                The Driver will lose all their data and it will not be
                recoverable.
              </Typography>
              <Typography
                variant="body1"
                style={{
                  textAlign: "center",
                  color: "#122E59",
                  marginBottom: "24px",
                  fontSize: "18px",
                  lineHeight: "1.5",
                  fontFamily: "Montserrat",
                }}
              >
                To prevent this Driver from creating an account again with the
                same email or phone number, press "Delete & Block."
              </Typography>
            </DialogContent>

            {/* Modal Actions */}
            <DialogActions
              sx={{
                border: "none",
                padding: 0,
                marginTop: "32px",
                justifyContent: "center",
                gap: "16px",
                fontFamily: "Montserrat",
              }}
            >
              {/* Delete & Block Button */}
              <Button
                onClick={handleDeleteAndBlock}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #FDA214",
                  color: "#FDA214",
                  padding: "12px 24px",
                  marginBottom: "30px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  minWidth: "160px",
                  fontFamily: "Montserrat",
                }}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "rgba(253, 162, 20, 0.1)";
                  (e.target as HTMLButtonElement).style.color = "#FDA214";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "transparent";
                }}
              >
                Delete & Block
              </Button>

              {/* Delete Account Button */}
              <Button
                onClick={handleDelete}
                style={{
                  backgroundColor: "#FDA214",
                  border: "none",
                  color: "white",
                  padding: "12px 24px",
                  marginBottom: "30px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  minWidth: "160px",
                  fontFamily: "Montserrat",
                }}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#e89412";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "#FDA214";
                }}
              >
                Delete Account
              </Button>

              {/* Cancel Button */}
              <Button
                onClick={handleCloseRemoveModal}
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid #FDA214",
                  color: "#FDA214",
                  padding: "12px 24px",
                  borderRadius: "24px",
                  marginBottom: "30px",
                  fontWeight: 500,
                  minWidth: "160px",
                  fontFamily: "Montserrat",
                }}
                onMouseEnter={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "rgba(253, 162, 20, 0.1)";
                }}
                onMouseLeave={(e) => {
                  (e.target as HTMLButtonElement).style.backgroundColor =
                    "transparent";
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </Box>
  );
}
