import React from "react";
import { ReactComponent as CustomLeftArrow } from "../../../../assets/icons/arrow-circle-left.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/solar_phone-rounded-broken.svg";
import { ReactComponent as VehicleIcon } from "../../../../assets/icons/ph_car-profile.svg";
import { ReactComponent as LicensePlateIcon } from "../../../../assets/icons/vaadin_password.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/solar_letter-broken.svg";
import { Button, Form } from "react-bootstrap";
import { ReactComponent as Pdf } from "../../../../assets/icons/Pdf.svg";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Typography, Box, Snackbar, Alert } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import DatabaseApi from "../../../../services/database_api";

import { ReactComponent as Defaulticons } from "../../../../assets/icons/defaultImg.svg";

interface Driver {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  password?: string;
  account_type?: string;
  company_name?: string;
  vehicle_type?: string;
  vehicle_color?: string;
  license_plate_number?: string;
  license_document?: string | null;
  towing_license?: string | null;
  insurance_document?: string | null;
  upload_company_document?: string | null;
  upload_vehicle_registration?: string | null;
  account_status?: string;
  created_at: string;
  updated_at: string;
  subscriptionStatus?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
}

const getDriverDocuments = (driver: Driver) => [
  {
    name: "Driving License",
    file: DatabaseApi.getDriverDoc(driver.license_document || "null") || null,
  },
  {
    name: "Towing License",
    file: DatabaseApi.getDriverDoc(driver.towing_license || "null") || null,
  },
  {
    name: "Insurance Document",
    file: DatabaseApi.getDriverDoc(driver.insurance_document || "null") || null,
  },
  {
    name: "Company Document",
    file:
      DatabaseApi.getDriverDoc(driver.upload_company_document || "null") ||
      null,
  },
  {
    name: "Vehicle Registration",
    file:
      DatabaseApi.getDriverDoc(driver.upload_vehicle_registration || "null") ||
      null,
  },
];

export default function PendingDriver() {
  const [selectedFile, setSelectedFile] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const driver = location.state?.driver as Driver;

  if (!driver) {
    return <div>No driver data available</div>;
  }
  const updateAccountStatus = async (driverId: number, status: string) => {
    const token = localStorage.getItem("authToken");
    const apiUrl = DatabaseApi.updateAccountStatusUrl();
    try {
      const response = await axios.put(
        apiUrl,
        {
          driver_id: driverId,
          account_status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Update response:", response.data);
      navigate("/admin/drivers", {
        state: { returnToStatus: "Pending" },
      });
    } catch (error) {
      console.error("Error updating account status:", error);
    }
  };

  const handleApprove = () => {
    const newStatus = driver.account_status === "Pending" ? "Activate" : "";
    updateAccountStatus(driver.id, newStatus);
    navigate("/admin/drivers", {
      state: { returnToStatus: "Pending" },
    });
  };

  const handleReject = () => {
    const newStatus = driver.account_status === "Pending" ? "Rejecte" : "";
    updateAccountStatus(driver.id, newStatus);
    setOpenSnackbar(true);
    // Navigate after a short delay to allow the Snackbar to be seen
    setTimeout(() => {
      navigate("/admin/drivers", {
        state: { returnToStatus: "Pending" },
      });
    }, 1000);
  };
  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const driverDocuments = getDriverDocuments(driver);

  const handleDocumentClick = (fileUrl: string | null) => {
    if (fileUrl) {
      // If the URL is relative, prepend your API base URL
      const fullUrl = fileUrl.startsWith("http")
        ? fileUrl
        : `${process.env.REACT_APP_API_URL}${fileUrl}`;

      // Determine file type
      const fileName = fileUrl.split("/").pop() || "document";
      const fileExtension = fileName.split(".").pop()?.toLowerCase();
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];

      if (imageExtensions.includes(fileExtension || "")) {
        setSelectedFile(fullUrl);
      } else if (fileExtension === "pdf") {
        window.open(fullUrl, "_blank");
      }
    }
  };

  const clearSelectedFile = () => {
    setSelectedFile(null);
  };

  return (
    <Box className="bg-white p-8 md:p-24 min-h-screen lg:ml-48 ">
      <div className="max-w-7xl mx-auto">
        <button
          onClick={() =>
            navigate("/admin/drivers", {
              state: { returnToStatus: "Pending" },
            })
          }
          style={{
            textDecoration: "none",
            color: "inherit",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            background: "none",
            border: "none",
            padding: 0,
            font: "inherit",
          }}
        >
          <CustomLeftArrow
            style={{ width: 45, height: 55, marginRight: 10, marginBottom: 10 }}
          />
          <Typography
            variant="h5"
            gutterBottom
            color="#2C3E50"
            style={{ fontFamily: "Montserrat",  fontWeight: "600" }}
          >
            Driver Pending Approval
          </Typography>
        </button>

        <div className="flex items-center justify-between">
          <div className="flex items-center mb-7">
            {/* <img
              src={`https://i.pravatar.cc/150?u=${driver.id}`}
              alt={`${driver.first_name} ${driver.last_name}`}
              className="w-20 h-20 rounded-full mr-4"
            /> */}
            <Defaulticons className="w-20 h-20 rounded-full mr-4" />
            <h2 className="text-2xl font-semibold">{`${driver.first_name} ${driver.last_name}`}</h2>
          </div>
          <div className="ml-auto flex space-x-2">
            <Button variant="success" onClick={handleApprove}>
              Approve Driver
            </Button>
            <Button variant="danger" onClick={handleReject}>
              Reject Driver
            </Button>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity="error"
                sx={{ width: "100%" }}
                elevation={6}
                variant="filled"
              >
                Driver Rejected
              </Alert>
            </Snackbar>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
          {/* First Row */}
          <Form.Group className="relative">
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
              className="z-10"
            >
              Phone Number
            </Form.Label>
            <PhoneIcon className="absolute left-2 top-8 w-7 h-5" />
            <Form.Control
              type="text"
              value={driver.phone}
              readOnly
              style={{ paddingLeft: "2.5rem" }}
            />
          </Form.Group>
          <Form.Group className="mb-4 relative">
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              Email
            </Form.Label>
            <EmailIcon className="absolute left-2 top-8 w-7 h-5" />
            <Form.Control
              type="email"
              value={driver.email}
              readOnly
              style={{ paddingLeft: "2.5rem" }}
            />
          </Form.Group>
          <Form.Group className="mb-4 relative">
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              Vehicle Type
            </Form.Label>
            <VehicleIcon className="absolute left-2 top-8 w-7 h-5" />
            <Form.Control
              type="text"
              value={driver.vehicle_type || "N/A"}
              readOnly
              style={{ paddingLeft: "2.5rem" }}
            />
          </Form.Group>

          {/* Second Row */}
          <Form.Group className="mb-4 relative">
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              License Plate Number
            </Form.Label>
            <LicensePlateIcon className="absolute left-2 top-8 w-7 h-5" />
            <Form.Control
              type="text"
              value={driver.license_plate_number || "N/A"}
              readOnly
              style={{ paddingLeft: "2.5rem" }}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              Account Type
            </Form.Label>
            <Form.Control
              type="text"
              value={driver.account_type || "N/A"}
              readOnly
            />
          </Form.Group>
          <Form.Group>
            <Form.Label
              style={{
                marginBottom: "0.0rem",
                position: "relative",
                top: "0.2rem",
              }}
            >
              Company Name
            </Form.Label>
            <Form.Control
              type="text"
              value={driver.company_name || "N/A"}
              readOnly
            />
          </Form.Group>
        </div>

        <div className="mt-6 space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {driverDocuments.map((doc, index) => (
              <div key={index} className="flex flex-col items-start w-full">
                <Form.Group className="w-full">
                  <Form.Label
                    className="font-medium"
                    style={{
                      marginBottom: "0.0rem",
                      position: "relative",
                      top: "0.1rem",
                    }}
                  >
                    {doc.name}
                  </Form.Label>
                  <div
                    className="flex items-center border p-4 rounded cursor-pointer w-full bg-gray-100 hover:bg-gray-200 select-none"
                    style={{ height: "80px" }}
                    onClick={() => handleDocumentClick(doc.file)}
                  >
                    {doc.file ? (
                      <>
                        <Pdf className="w-10 h-10 mr-3" />
                        <span className="text-sm">{doc.name}</span>
                      </>
                    ) : (
                      <>
                        <Pdf className="w-10 h-10 mr-3 text-gray-400" />
                        <p className="text-sm text-gray-400">
                          No document uploaded
                        </p>
                      </>
                    )}
                  </div>
                </Form.Group>
              </div>
            ))}
          </div>

          {selectedFile && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
              onClick={clearSelectedFile}
            >
              <div
                className="max-w-4xl max-h-[80vh] bg-white p-4 rounded-lg"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-end mb-2">
                  <button
                    onClick={clearSelectedFile}
                    className="text-red-500 hover:text-red-700"
                  >
                    Close
                  </button>
                </div>
                <img
                  src={selectedFile}
                  alt="Document Preview"
                  className="max-w-full max-h-[70vh] object-contain mx-auto"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Box>
  );
}
