class DatabaseApi {
  static mainDoc = "https://marquelwrecking.com/portal/public/";

  static mainUrl = "https://marquelwrecking.com/portal/public/api/";

  static adminLogin = `${this.mainUrl}AdminLogin`;

  // Method to fetch image dynamically by providing filePath
  static getDriverDoc(filePath: string ): string {
    filePath = filePath.replaceAll("storage/","");
    return `${this.mainDoc}${filePath}`;
  }
  static getDriverProfile(filePath: string ): string {
    filePath = filePath.replaceAll("storage/","");
    return `${this.mainDoc}${filePath}`;
  }
  static getUserProfile(filePath: string ): string {
    filePath = filePath.replaceAll("storage/","");
    return `${this.mainDoc}${filePath}`;
  }
  static getTicketDoc(filePath: string): string {
    filePath = filePath.replaceAll("storage/","");
    return `${this.mainDoc}${filePath}`;
  }
  static getBannerDoc(filePath: string): string {
    filePath = filePath.replaceAll("storage/", "");
    return `${this.mainDoc}${filePath}`;
  }

  // Method to fetch users by admin

  static getAllUserUrl(
    pageNumber: number, 
    query: string, 
    accountStatus?: string, 
    type?: string
  ) {
    const params = new URLSearchParams({
      page_number: pageNumber.toString(),
      search: query,
    });
  
    // Add type parameter if provided
    if (type) {
      params.append('type', type);
    }
  
    if (accountStatus) {
      params.append('account_status', accountStatus);
    }
  
    return `${this.mainUrl}show-all-user-by-admin?${params.toString()}`;
  }


  
  // Method to update User account status
  static updateAccountStatusUserUrl() {
    return `${this.mainUrl}update-account-status-user`;
  }
  // Method to delete a user by admin
  static deleteUserUrl() {
    return `${this.mainUrl}delete-user-by-admin`;
  }

  // Method to get paginated URL for drivers
  static getDriversUrl(pageNumber: number, accountStatus?: string, type?: string) {
    let url = `${this.mainUrl}show-all-driver-by-admin?page_number=${pageNumber}`;
    const params = new URLSearchParams({
      page_number: pageNumber.toString(),
    });

    if (type) {
      params.append('type', type);
    }
    if (accountStatus) {
      params.append('account_status', accountStatus);
    }

    // if (accountStatus) {
    //   url += `&account_status=${accountStatus}`;
    // }
    return `${this.mainUrl}show-all-driver-by-admin?${params.toString()}`;
  }

  // Method to get URL for searching drivers
  static searchDrivers(
    pageNumber: number,
    searchQuery: string,
    accountStatus?: string,

  ) {
    let url = `${
      this.mainUrl
    }show-all-driver-by-admin?page_number=${pageNumber}&search=${encodeURIComponent(
      searchQuery
    )}`;
    return url;
  }

  // Method to update driver account status
  static updateAccountStatusUrl() {
    return `${this.mainUrl}update-account-status`;
  }
  
  // Method to delete a driver by admin
  static deleteDriverUrl(driverId: number) {
    return `${this.mainUrl}delete-driver-by-admin/${driverId}`;
  }

  // SupportTicket

  // Method to fetch tickets by admin
  static getTicketsByAdminUrl(pageNumber: number, type: "driver" | "user") {
    return `${this.mainUrl}show-ticket-by-admin?page_number=${pageNumber}&type=${type}`;
  }
  // Method to get URL for searching tickets by admin
  static searchTickets(
    pageNumber: number,
    searchQuery: string,
    type: string = ""
  ) {
    return `${
      this.mainUrl
    }show-ticket-by-admin?page_number=${pageNumber}&type=${encodeURIComponent(
      type
    )}&search=${encodeURIComponent(searchQuery)}`;
  }
  // Method update Ticket Status Url by admin
  static updateTicketStatus(ticketId: string, status: string) {
    return `${this.mainUrl}update-status-by-admin`;
  }

  // Method to fetch data for the Admin Dashboard
  static getAdminDashboardUrl() {
    return `${this.mainUrl}Admin-Dashboard`;
  }

  // Method to fetch data for the Subscription-Summary
  static getSubscriptionSummaryUrl() {
    return `${this.mainUrl}Subscription-Summary`;
  }

  // Method to fetch data for the Performing-drivers
  static getPerformingDriversUrl() {
    return `${this.mainUrl}Performing-drivers`;
  }
  // Method to fetch data for the contect-details
  static getContectDetails() {
    return `${this.mainUrl}Admin-Details`;
  }
  //Method to update data for the contect-details
  static updateContectDetails() {
    return `${this.mainUrl}update-Admin-Details`;
  }

  // Method to update data for the user-Subscription
  static getUserSubscription(): string {
    return `${this.mainUrl}user-Subscription`;
  }

  // method to post data for the Subscription plan
  static postSubscriptionPlan(): string {
    return `${this.mainUrl}create-plan`;
  }
  // method to get data for the Subscription plan
  static getSubscriptionPlan(): string {
    return `${this.mainUrl}show-plan`;
  }

  // payout api data
  static putPayoutPlan(): string {
    return `${this.mainUrl}create-payout`;
  }
  
  static getPayoutPlan(): string {
    return `${this.mainUrl}show-payout`;
  }

  // method to update plan data for the Subscription plan subscription-plans/x
  static putSubscriptionPlan(): string {
    return `${this.mainUrl}update-plan`;
  }

  // method to delete data for the Subscription
  static deleteSubscriptionPlan(PlanID: number) {
    return `${this.mainUrl}delete-plan/${PlanID}`;
  }

  static saveMultipartImage() {
    return `${this.mainUrl}save-Multipart-Image`;
  }

}

export default DatabaseApi;
