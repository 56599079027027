import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert,
  Button as MuiButton,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import DatabaseApi from "../../../services/database_api";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

interface ContactDetails {
  website: string;
  address: string;
  phone: string;
  whatsapp: string;
  email: string;
  instagram: string;
  x_twitter: string;
  facebook: string;
}

const defaultContactDetails: ContactDetails = {
  website: "",
  address: "",
  phone: "",
  whatsapp: "",
  email: "",
  instagram: "",
  x_twitter: "",
  facebook: "",
};
const ContactDetails = () => {
  const navigate = useNavigate();
  const [contactDetail, setContactDetails] = useState<ContactDetails>(
    defaultContactDetails
  );
  const [initialValues, setInitialValues] = useState<ContactDetails>(
    defaultContactDetails
  );
  const [formChanged, setFormChanged] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showExitDialog, setShowExitDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [pendingNavigationPath, setPendingNavigationPath] = useState<
    string | null
  >(null);
  const [nextLocation, setNextLocation] = useState<string | null>(null);

  const fetchContactData = async () => {
    const apiUrl = DatabaseApi.getContectDetails();
    const token = localStorage.getItem("authToken");

    try {
      setLoading(true);
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data && response.data.data) {
        const fetchedData = response.data.data;

        // Ensure all fields have at least empty string values
        const sanitizedData = {
          website: fetchedData.website || "",
          address: fetchedData.address || "",
          phone: fetchedData.phone || "",
          whatsapp: fetchedData.whatsapp || "",
          email: fetchedData.email || "",
          instagram: fetchedData.instagram || "",
          x_twitter: fetchedData.x_twitter || "",
          facebook: fetchedData.facebook || "",
        };

        console.log("Fetched data:", sanitizedData);
        setContactDetails(sanitizedData);
        setInitialValues(sanitizedData);
      } else {
        setError("No data available.");
      }
      setError(null);
    } catch (error) {
      setError("Failed to fetch contact details. Please try again later.");
      console.error("Error fetching contact details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactData();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    const handleBeforeNavigate = (e: MouseEvent) => {
      const target = e.target as HTMLAnchorElement;
      if (target.tagName === "A" && hasUnsavedChanges) {
        e.preventDefault();
        setNextLocation(target.href);
        setShowExitDialog(true);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    document.addEventListener("click", handleBeforeNavigate);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      document.removeEventListener("click", handleBeforeNavigate);
    };
  }, [hasUnsavedChanges]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContactDetails((prev) => ({
      ...prev,
      [name]: value,
    }));

    const newDetails = {
      ...contactDetail,
      [name]: value,
    };

    const hasChanges = Object.keys(newDetails).some(
      (key) =>
        newDetails[key as keyof ContactDetails] !==
        initialValues[key as keyof ContactDetails]
    );
    setHasUnsavedChanges(hasChanges);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await saveChanges();
  };

  const saveChanges = async () => {
    const apiUrl = DatabaseApi.updateContectDetails();
    const token = localStorage.getItem("authToken");

    try {
      setLoading(true);
      const response = await axios.put(apiUrl, contactDetail, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // if (response.status === 200) {
      //   setInitialValues(contactDetail);
      //   setHasUnsavedChanges(false);
      //   setShowSuccessSnackbar(true);
      //   setTimeout(() => setSuccessMessage(null), 1000);
      // }
      if (response.status === 200) {
        setInitialValues(contactDetail); // Update initial values after successful save
        setFormChanged(false); // Reset form changed state
        setShowSuccessSnackbar(true);
        return true;
      }
      return false;
    } catch (error) {
      setError("Failed to update contact details. Please try again.");
      setTimeout(() => setError(null), 3000);
      console.error("Error updating contact details:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    const saved = await saveChanges();
    if (saved && nextLocation) {
      window.location.href = nextLocation;
    }
    setShowExitDialog(false);
  };
  const handleDiscard = () => {
    setContactDetails(initialValues);
    setHasUnsavedChanges(false);
    setShowExitDialog(false);
    if (nextLocation) {
      window.location.href = nextLocation;
    }
  };

  // Handle dialog actions
  const handleDontSave = () => {
    setFormChanged(false);
    setShowExitDialog(false);
    setContactDetails(initialValues); // Reset form to initial values
  };
  // Function to handle saving and then navigating
  const handleSaveAndExit = async () => {
    const apiUrl = DatabaseApi.updateContectDetails();
    const token = localStorage.getItem("authToken");

    try {
      setLoading(true);
      const response = await axios.put(apiUrl, contactDetail, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200) {
        setFormChanged(false);
        setShowExitDialog(false);
        setShowSuccessSnackbar(true);
      }
    } catch (err) {
      setError("Failed to save changes. Please try again.");
      setTimeout(() => setError(null), 1000);
      console.error("Error saving changes:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccessSnackbar(false);
  };

  if (loading && !contactDetail.website) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "246px",
        marginTop: "43px",
        fontFamily: "Montserrat",
      }}
    >
      <Container className="py-6">
        <Typography
          variant="h5"
          gutterBottom
          color="#2C3E50"
          style={{ fontFamily: "Montserrat", fontWeight: "600" }}
        >
          Contact Details
        </Typography>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group controlId="website">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Website
                </Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  value={contactDetail.website}
                  onChange={handleInputChange}
                  placeholder="Enter website URL"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="address">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Address
                </Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={contactDetail.address}
                  onChange={handleInputChange}
                  placeholder="Enter address"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="phone">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Telephone
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="phone"
                  value={contactDetail.phone}
                  onChange={handleInputChange}
                  placeholder="Enter telephone number"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="whatsapp">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Whatsapp
                </Form.Label>
                <Form.Control
                  type="tel"
                  name="whatsapp"
                  value={contactDetail.whatsapp}
                  onChange={handleInputChange}
                  placeholder="Enter WhatsApp number"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="email">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Email
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={contactDetail.email}
                  onChange={handleInputChange}
                  placeholder="Enter email address"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="instagram">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Instagram
                </Form.Label>
                <Form.Control
                  type="text"
                  name="instagram"
                  value={contactDetail.instagram}
                  onChange={handleInputChange}
                  placeholder="Enter Instagram handle"
                  style={{ fontFamily: "Montserrat" }}
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="x_twitter">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  X (Formerly Twitter)
                </Form.Label>
                <Form.Control
                  style={{ fontFamily: "Montserrat" }}
                  type="text"
                  name="x_twitter"
                  value={contactDetail.x_twitter}
                  onChange={handleInputChange}
                  placeholder="Enter X handle"
                />
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group controlId="facebook">
                <Form.Label
                  style={{
                    color: "#344054",
                    fontWeight: "bold",
                    fontFamily: "Montserrat",
                  }}
                >
                  Facebook
                </Form.Label>
                <Form.Control
                  style={{ fontFamily: "Montserrat" }}
                  type="text"
                  name="facebook"
                  value={contactDetail.facebook}
                  onChange={handleInputChange}
                  placeholder="Enter Facebook URL"
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="d-flex justify-content-center gap-10 mt-14">
            <Button
              style={{
                backgroundColor: "#FDA214",
                color: "#FFFFFF",
                borderColor: "transparent", // Sets border color directly
                fontFamily: "Montserrat",
              }}
              type="submit" // Change button type to avoid form submission
              className="px-4 no-focus-outline"
              disabled={!hasUnsavedChanges}
            >
              Update
            </Button>
          </div>
        </Form>
        <Dialog
          open={showExitDialog}
          onClose={setShowExitDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Changes are not saved. Are you sure you want to exit page?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ padding: 2 }}>
            <MuiButton
              onClick={() => setShowExitDialog(false)}
              variant="outlined"
            >
              Cancel
            </MuiButton>
            <MuiButton
              onClick={handleDiscard}
              variant="contained"
              color="error"
            >
              Don't Save
            </MuiButton>
            <MuiButton
              onClick={handleSave}
              variant="contained"
              color="success"
              autoFocus
            >
              Save and Exit
            </MuiButton>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={showSuccessSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Contact details updated successfully!
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
};
export default ContactDetails;
