import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../redux/store";
import { ReactComponent as CloseIcon } from "../../../assets/icons/ep_circle-close-filled.svg";
import { X } from "react-bootstrap-icons";
import {
  addPlan,
  updatePlan as updatePlanAction,
  deletePlan as deletePlanAction,
  setPlans,
} from "../../../redux/slices/subscriptionSlice";
import { Button } from "react-bootstrap";

import {
  Box,
  Alert,
  AlertTitle,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  TextField,
  InputAdornment,
  Grid,
  Button as MuiButton,
  Card,
  CardContent,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { ReactComponent as UploadIcon } from "../../../assets/icons/upload.svg";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Delete } from "../../../assets/icons/delete.svg";
import Pagination from "../PaginationComp/Paginations";
import DatabaseApi from "../../../services/database_api";
import axios from "axios";
import { CircularProgress } from '@mui/material';

interface UserProfile {
  id: number;
  name: string;
  email: string;
  plan: string;
  startdate: string;
  enddate: string;
}

interface ApiResponse {
  status: string;
  message: string;
  data: any[];
  "Total Revenue": number;
  "Total Subscribers": number;
  current_page: number;
  total_page: number;
  total_data: number;
}

// Component Interface
interface SubscriptionPlanData {
  id: number;
  planName: string;
  duration: string;
  price: string;
  distanceCovered: string;
  journeysIncluded: string;
  ratePerExtraKM: string;
  accidentRecoveryFee: string;
  precipiceFee: string;
  banner: File | null;
  bannerUrl?: string; // Added optional bannerUrl property
}
// Transformation Utilities
const transformAPIToComponentData = (apiData: any): SubscriptionPlanData => ({
  id: apiData.id || Date.now(),
  planName: apiData.plan_name,
  duration: apiData.duration,
  price: apiData.price,
  distanceCovered: apiData.distance_covered_per_journey,
  journeysIncluded: apiData.no_of_journeys_included,
  ratePerExtraKM: apiData.rate_per_extra_km,
  accidentRecoveryFee: apiData.accident_recovery_fee,
  precipiceFee: apiData.revine_ditch_precipice_fee,
  banner: apiData.banner || null,
});

// Transform component data to API format
const transformComponentToAPIData = (
  componentData: SubscriptionPlanData
): any => ({
  plan_name: componentData.planName,
  duration: componentData.duration,
  price: componentData.price,
  distance_covered_per_journey: componentData.distanceCovered,
  no_of_journeys_included: componentData.journeysIncluded,
  rate_per_extra_km: componentData.ratePerExtraKM,
  accident_recovery_fee: componentData.accidentRecoveryFee,
  revine_ditch_precipice_fee: componentData.precipiceFee,
  banner: componentData.banner,
});

const initialPlanData: SubscriptionPlanData = {
  id: 0,
  planName: "",
  duration: "",
  price: "",
  distanceCovered: "",
  journeysIncluded: "",
  ratePerExtraKM: "",
  accidentRecoveryFee: "",
  precipiceFee: "",
  banner: null,
};

const Subscriptions: React.FC = () => {
  // State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
  const [totalRevenue, setTotalRevenue] = useState<number>(0);
  const [totalSubscribers, setTotalSubscribers] = useState<number>(0);

  // redux
  const dispatch = useDispatch<AppDispatch>();
  const plans = useSelector((state: RootState) => state.subscriptions);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [editingPlan, setEditingPlan] = useState<SubscriptionPlanData | null>(
    null
  );
  const [planData, setPlanData] =
    useState<SubscriptionPlanData>(initialPlanData);
  const [bannerPreview, setBannerPreview] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [showError, setShowError] = useState(false);

  // New state for delete confirmation modal //
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [planToDelete, setPlanToDelete] = useState<SubscriptionPlanData | null>(
    null
  );

  // APi call
  const fetchDashboardData = async () => {
    const token = localStorage.getItem("authToken");
    const apiUrl = DatabaseApi.getUserSubscription();

    try {
      setLoading(true);
      const response = await axios.get<ApiResponse>(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === "true") {
        setUserProfiles(response.data.data);
        setTotalRevenue(response.data["Total Revenue"]);
        setTotalSubscribers(response.data["Total Subscribers"]);
        setTotalPages(response.data.total_page); // Set total pages for pagination
        setTotalItems(response.data.total_data);
        setCurrentPage(response.data.current_page);
      }
      setError(null);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setError("Failed to fetch dashboard data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  // Validation Utility
  const validateForm = (): boolean => {
    const errors: Record<string, string> = {};
    const requiredFields: (keyof SubscriptionPlanData)[] = [
      "planName",
      "duration",
      "price",
      "distanceCovered",
      "journeysIncluded",
      "ratePerExtraKM",
      "accidentRecoveryFee",
      "precipiceFee",
    ];

    // Numeric fields that should only contain numbers
    const numericFields = [
      "duration",
      "price",
      "distanceCovered",
      "journeysIncluded",
      "ratePerExtraKM",
      "accidentRecoveryFee",
      "precipiceFee",
    ];

    // Add banner validation
    if (!planData.banner) {
      errors["banner"] = "Banner is required";
    }

    requiredFields.forEach((field) => {
      const value = planData[field];

      // Check if field is empty
      if (!value || (typeof value === "string" && value.trim() === "")) {
        errors[field] = `${field} is required`;
        return;
      }

      // Validate numeric fields
      if (numericFields.includes(field)) {
        // Check if the value contains only numbers using regex
        if (!/^\d*\.?\d*$/.test(value as string)) {
          errors[field] = `${field} must contain only numbers`;
          return;
        }

        // Convert to number and validate
        const numValue = parseFloat(value as string);
        if (isNaN(numValue) || numValue <= 0) {
          errors[field] = `${field} must be a positive number`;
        }
      }
    });

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return false;
    }
    return true;
  };

  const createPlan = async (planData: SubscriptionPlanData) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No authentication token found");
      }

      let bannerUrl = "";

      // Upload the banner if it's a File
      if (planData.banner instanceof File) {
        const bannerFormData = new FormData();
        bannerFormData.append("file", planData.banner);
        bannerFormData.append("folder", "public");

        const bannerResponse = await fetch(DatabaseApi.saveMultipartImage(), {
          method: "POST",
          body: bannerFormData,
          redirect: "follow" as RequestRedirect,
        });

        const bannerResult = await bannerResponse.json();
        if (!bannerResponse.ok || !bannerResult.half_url) {
          throw new Error("Failed to upload the banner");
        }

        bannerUrl = bannerResult.half_url; // Extract "half_url" from the response
      }

      const formData = new FormData();
      const apiData = transformComponentToAPIData(planData);

      Object.entries(apiData).forEach(([key, value]) => {
        if (value !== null && value !== undefined) {
          formData.append(key, value as string | File);
        }
      });

      // Include the banner URL in the formData
      if (bannerUrl) {
        formData.append("banner", bannerUrl);
      }

      const response = await axios.post(
        DatabaseApi.postSubscriptionPlan(),
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status !== "true") {
        throw new Error(response.data.message || "Plan creation failed");
      }

      const transformedPlan = transformAPIToComponentData(response.data.data);

      // // Dispatch the new plan
      // dispatch(addPlan(transformedPlan));

      return transformedPlan;
    } catch (error) {
      console.error("Error creating subscription plan:", error);
      setError(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
      throw error;
    }
  };

  const fetchPlans = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const apiUrl = DatabaseApi.getSubscriptionPlan();
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.data.status === "true") {
        const transformedPlans = response.data.data.map((plan: any) => ({
          ...transformAPIToComponentData(plan),
          bannerUrl: plan.banner ? DatabaseApi.getBannerDoc(plan.banner) : null,
        }));
        dispatch(setPlans(transformedPlans));
      }
    } catch (error) {
      console.error("Error fetching plans:", error);
      setError("Failed to fetch subscription plans");
    }
  };

  useEffect(() => {
    fetchPlans();
  }, []); // Fetch plans when component mounts

  const updateExistingPlan = async (PlanData: SubscriptionPlanData) => {
    try {
      const token = localStorage.getItem("authToken");
      if (!token) {
        throw new Error("No authentication token found");
      }

      if (!PlanData.id) {
        throw new Error("Plan ID is required for update");
      }

      let bannerUrl = null;

      // If banner is a file, upload it to get the URL
      if (PlanData.banner instanceof File) {
        const bannerFormData = new FormData();
        bannerFormData.append("file", PlanData.banner);
        bannerFormData.append("folder", "public");

        const bannerResponse = await fetch(DatabaseApi.saveMultipartImage(), {
          method: "POST",
          body: bannerFormData,
          redirect: "follow",
        });

        const bannerResult = await bannerResponse.json();

        if (!bannerResponse.ok || !bannerResult.half_url) {
          throw new Error("Failed to upload the banner image");
        }

        bannerUrl = bannerResult.half_url; // Extract the uploaded image URL
      }

      // Construct headers
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      // Prepare payload
      const raw = JSON.stringify({
        id: PlanData.id,
        plan_name: PlanData.planName,
        duration: PlanData.duration,
        price: PlanData.price,
        distance_covered_per_journey: PlanData.distanceCovered,
        no_of_journeys_included: PlanData.journeysIncluded,
        rate_per_extra_km: PlanData.ratePerExtraKM,
        accident_recovery_fee: PlanData.accidentRecoveryFee,
        revine_ditch_precipice_fee: PlanData.precipiceFee,
        banner: bannerUrl || null,
      });

      // Construct request options
      const requestOptions: RequestInit = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const apiUrl = DatabaseApi.putSubscriptionPlan();
      console.log("Update Plan API URL:", apiUrl);

      // Make the API call
      const response = await fetch(apiUrl, requestOptions);

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Failed to update the plan");
      }

      const result = await response.json();
      console.log("Plan updated successfully:", result);

      if (result.status !== "true") {
        throw new Error(result.message || "Plan update failed");
      }

      // Transform the updated plan data
      const transformedPlan = transformAPIToComponentData(result.data);
      dispatch(updatePlanAction(transformedPlan)); // Update in state

      return transformedPlan;
    } catch (error) {
      console.error("Error updating plan:", error);
      throw error instanceof Error
        ? error
        : new Error("An unexpected error occurred");
    }
  };

  const deletePlan = async (planId: number) => {
    const apiUrl = DatabaseApi.deleteSubscriptionPlan(Number(planId));
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.delete(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200 || response.status === 204) {
        // Success: Plan deleted
        console.log(`Plan with ID ${planId} deleted successfully.`);
        dispatch(deletePlanAction(planId));
        return { success: true, message: "Plan deleted successfully" };
      } else {
        // Unexpected status code
        console.warn(
          `Unexpected response: ${response.status} - ${response.statusText}`
        );
        return { success: false, message: "Unexpected server response" };
      }
    } catch (error) {
      console.error("Plan deletion error:", error);
    }
  };

  const validateField = (name: string, value: string) => {
    if (!value || value.trim() === "") {
      return `${name} is required`;
    }
    if (
      [
        "duration",
        "price",
        "distanceCovered",
        "journeysIncluded",
        "ratePerExtraKM",
        "accidentRecoveryFee",
        "precipiceFee",
      ].includes(name)
    ) {
      if (isNaN(parseFloat(value)) || parseFloat(value) <= 0) {
        return `${name} must be a positive number`;
      }
    }
    return "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPlanData((prev) => ({ ...prev, [name]: value }));

    const error = validateField(name, value);
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const isFormValid = () => {
    const requiredFields = [
      "planName",
      "duration",
      "price",
      "distanceCovered",
      "journeysIncluded",
      "ratePerExtraKM",
      "accidentRecoveryFee",
      "precipiceFee",
    ];

    return (
      requiredFields.every(
        (field) => planData[field as keyof typeof planData] && !errors[field]
      ) && !!planData.banner
    );
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      setShowError(true);
      return;
    }

    setLoading(true);
    try {
      if (editingPlan) {
        // For updating an existing plan
        const updatedPlan = await updateExistingPlan({
          ...planData,
          id: editingPlan.id,
        });

        // Dispatch only ONCE
        dispatch(updatePlanAction(updatedPlan));
      } else {
        // For creating a new plan
        const newPlan = await createPlan(planData);

        // Dispatch only ONCE
        dispatch(addPlan(newPlan));
      }

      handleCloseModal();
    } catch (error) {
      console.error("Error saving plan:", error);
      setError("Failed to save plan");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleOpenModal = (plan?: SubscriptionPlanData) => {
    setOpenModal(true);
    if (plan) {
      // Editing existing plan
      setEditingPlan(plan);
      setPlanData(plan);
    } else {
      // Creating new plan
      resetPlanData();
    }
  };
  const resetPlanData = () => {
    setPlanData({
      id: 0,
      planName: "",
      duration: "",
      price: "",
      distanceCovered: "",
      journeysIncluded: "",
      ratePerExtraKM: "",
      accidentRecoveryFee: "",
      precipiceFee: "",
      banner: null,
    });
    setBannerPreview(null);
    setEditingPlan(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingPlan(null);
    setPlanData({
      id: 0,
      planName: "",
      duration: "",
      price: "",
      distanceCovered: "",
      journeysIncluded: "",
      ratePerExtraKM: "",
      accidentRecoveryFee: "",
      precipiceFee: "",
      banner: null,
    });
  };

  const handleBannerUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      // Update state with the selected file
      setPlanData((prev) => ({ ...prev, banner: file }));

      // Preview the banner
      const reader = new FileReader();
      reader.onloadend = () => {
        setBannerPreview(reader.result as string);
      };
      reader.readAsDataURL(file);

      try {
        // Prepare FormData
        const formdata = new FormData();
        formdata.append("file", file);
        formdata.append("folder", "public");

        // Fetch API call
        const response = await fetch(DatabaseApi.saveMultipartImage(), {
          method: "POST",
          body: formdata,
          redirect: "follow",
        });

        const result = await response.json();
        if (result.half_url) {
          setPlanData((prev) => ({
            ...prev,
            bannerUrl: DatabaseApi.getBannerDoc(result.half_url),
          }));
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };

  const handleRemoveBanner = () => {
    setPlanData((prevData) => ({
      ...prevData,
      banner: null,
      bannerUrl: undefined
    }));
    setBannerPreview(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setErrors(prev => ({ ...prev, banner: "" })); // Clear any banner-related errors
  };

  const handleEdit = (plan: SubscriptionPlanData) => {
    setEditingPlan(plan);
    setPlanData(plan);
    setOpenModal(true);
  };

  const handleDeleteClick = (plan: SubscriptionPlanData) => {
    setPlanToDelete(plan);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (planToDelete) {
      try {
        await deletePlan(planToDelete.id);
        setDeleteModalOpen(false);
        setPlanToDelete(null);
      } catch (error) {
        console.error("Plan deletion error:", error);
        setError("Failed to delete plan");
      }
    }
  };
  const handleDeleteCancel = () => {
    setDeleteModalOpen(false);
    setPlanToDelete(null);
  };

  // loding errror and making it more  to handel error
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
         <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <div
      className="p-6 bg-white min-h-screen"
      style={{
        maxWidth: "90%",
        marginLeft: "237px",
        marginTop: "38px",
        fontFamily: "Montserrat",
      }}
    >
      <Box sx={{ p: 3.5, bgcolor: "background.default" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 5,
            fontFamily: "Montserrat",
          }}
        >
          <Typography
            variant="h5"
            color="#2C3E50"
            style={{ fontFamily: "Montserrat", fontWeight: "600" }}
          >
            Subscriptions
          </Typography>
          <Box sx={{ display: "flex", gap: 2, fontFamily: "Montserrat" }}>
            <Button
              variant="contained"
              style={{
                width: "18rem",
                height: "3rem",
                fontFamily: "Montserrat",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#FDA214",
                color: "white",
                fontWeight: 300,
                fontSize: "1.1rem",
              }}
              onClick={() => handleOpenModal()}
            >
              <AddIcon
                style={{ marginRight: "10px", fontFamily: "Montserrat" }}
              />
              Subscription Plan
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: "flex", mb: 3, fontFamily: "Montserrat" }}>
          <Paper
            elevation={0}
            sx={{
              flex: 1,
              mr: 2,
              p: 2,
              background: "#F0F3FA",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontFamily: "Montserrat",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
              color="#345C72"
            >
              Total Revenue
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textAlign: "right",
                color: "#345C72",
                fontFamily: "Montserrat",
              }}
            >
              $ {totalRevenue.toLocaleString()}
            </Typography>
          </Paper>
          <Paper
            elevation={0}
            sx={{
              flex: 1,
              p: 2,
              background: "#F0F3FA",
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              fontFamily: "Montserrat",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
              color="#345C72"
            >
              Total Subscribers
            </Typography>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textAlign: "right",
                color: "#345C72",
                fontFamily: "Montserrat",
              }}
            >
              {totalSubscribers.toLocaleString()}
            </Typography>
          </Paper>
        </Box>

        {/* Subscription Plan Cards */}

        <Box
          sx={{
            display: "flex",
            gap: 2,
            mb: 3,
            flexWrap: "wrap",
            fontFamily: "Montserrat",
          }}
        >
          {plans.map((plan: SubscriptionPlanData) => (
            <Card
              key={plan.id}
              sx={{
                width: "calc(25% - 16px)",
                minWidth: 200,
                height: 120, // Fixed height for all cards
                backgroundColor: "#F0F3FA",
                boxShadow: "none", // Removes shadow
                border: "none", // Ensures no border
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                fontFamily: "Montserrat",
              }}
            >
              {/* <CardContent color="#345C72"> */}
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Montserrat",
                }}
                color="#345C72"
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#345C72",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: 1.2,
                    maxHeight: "2.4em", // Allows for 2 lines of text
                    fontFamily: "Montserrat",
                  }}
                >
                  {plan.planName}
                </Typography>
              </CardContent>
              <CardActions>
                <MuiButton
                  size="small"
                  onClick={() => handleEdit(plan)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FDA214",
                      color: "white",
                      fontFamily: "Montserrat",
                      "& img": {
                        filter: "brightness(0) invert(1)", // This makes the image appear white
                      },
                    },
                  }}
                >
                  <Edit className="mr-2" width="16" />
                </MuiButton>
                <MuiButton
                  size="small"
                  onClick={() => handleDeleteClick(plan)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FDA214",
                      fontFamily: "Montserrat",
                      color: "white",
                      "& img": {
                        filter: "brightness(0) invert(1)", // This makes the image appear white
                      },
                    },
                  }}
                >
                  <Delete className="mr-2" width="16" />
                </MuiButton>
              </CardActions>
            </Card>
          ))}
        </Box>

        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead
              sx={{ backgroundColor: "#F0F3FA", fontFamily: "Montserrat" }}
            >
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Email
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Plan
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  Start Date
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", fontFamily: "Montserrat" }}
                >
                  End Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userProfiles && userProfiles.length > 0 ? (
                userProfiles.map((profile, index) => (
                  <TableRow
                    key={profile.id || index}
                    style={{ fontFamily: "Montserrat" }}
                  >
                    <TableCell style={{ fontFamily: "Montserrat" }}>
                      {profile.name}
                    </TableCell>
                    <TableCell style={{ fontFamily: "Montserrat" }}>
                      {profile.email}
                    </TableCell>
                    <TableCell style={{ fontFamily: "Montserrat" }}>
                      {profile.plan}
                    </TableCell>
                    <TableCell style={{ fontFamily: "Montserrat" }}>
                      {profile.startdate}
                    </TableCell>
                    <TableCell style={{ fontFamily: "Montserrat" }}>
                      {profile.enddate}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    style={{ fontFamily: "Montserrat", textAlign: "center" }}
                  >
                    No user profiles found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          className="d-flex justify-content-center align-items-center mt-4 flex-wrap p-3 rounded-lg"
          style={{ height: "100px", fontFamily: "Montserrat" }}
        >
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </div>
      </Box>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            fontFamily: "Montserrat",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 750, // Adjust as per requirement
            maxHeight: "90vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
            "&::-webkit-scrollbar": {
              width: "8px", // Adjust the scrollbar width
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "white", // Scrollbar track color
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "white", // Scrollbar thumb color
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "white", // Hover color for scrollbar thumb
              },
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              top: "70%",
              justifyContent: "space-between",
              alignItems: "center",
              fontFamily: "Montserrat",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 3, fontWeight: "bold", fontFamily: "Montserrat" }}
            >
              {editingPlan ? "Edit Plan" : "Create Plan"}
            </Typography>

            <MuiButton
              onClick={handleCloseModal}
              sx={{
                minWidth: "auto",
                p: 0,
                color: "#FDA214",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                mt: -3,
                "&:hover": {
                  bgcolor: "#FB923C",
                  color: "white",
                },
              }}
            >
              <X />
            </MuiButton>
          </Box>
          {showError && !isFormValid() && (
            <Alert severity="error" style={{ fontFamily: "Montserrat" }}>
              <AlertTitle style={{ fontFamily: "Montserrat" }}>
                Error
              </AlertTitle>
              Please fill in all required fields correctly before submitting.
            </Alert>
          )}
          <form
            onSubmit={handleSubmit}
            className="space-y-6 p-6"
            style={{ fontFamily: "Montserrat" }}
          >
            <Grid container spacing={1} style={{ fontFamily: "Montserrat" }}>
              <Grid item xs={6} md={6} style={{ fontFamily: "Montserrat" }}>
                <TextField
                  fullWidth
                  label="Plan Name"
                  name="planName"
                  value={planData.planName}
                  onChange={handleChange}
                  error={!!errors.planName}
                  helperText={errors.planName}
                  sx={{
                    mb: 2,
                    fontFamily: "Montserrat",
                    maxWidth: "300px",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ fontFamily: "Montserrat" }}
                      >
                        months
                      </InputAdornment>
                    ),
                  }}
                  style={{ fontFamily: "Montserrat" }}
                  placeholder="xyz"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Duration"
                  name="duration"
                  value={planData.duration}
                  onChange={handleChange}
                  error={!!errors.duration}
                  helperText={errors.duration}
                  placeholder="00"
                  InputProps={{
                    endAdornment: (
                      <span
                        style={{
                          marginLeft: "8px",
                          color: "gray",
                        }}
                      >
                        months
                      </span>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Price"
                  name="price"
                  value={planData.price}
                  onChange={handleChange}
                  error={!!errors.price}
                  helperText={errors.price}
                  placeholder="300"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Distance Covered per Journey"
                  name="distanceCovered"
                  value={planData.distanceCovered}
                  onChange={handleChange}
                  error={!!errors.distanceCovered}
                  helperText={errors.distanceCovered}
                  placeholder="00"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">KM</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="No. of Journeys Included"
                  name="journeysIncluded"
                  value={planData.journeysIncluded}
                  onChange={handleChange}
                  error={!!errors.journeysIncluded}
                  helperText={errors.journeysIncluded}
                  placeholder="6"
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Rate per extra KM"
                  name="ratePerExtraKM"
                  value={planData.ratePerExtraKM}
                  onChange={handleChange}
                  error={!!errors.ratePerExtraKM}
                  helperText={errors.ratePerExtraKM}
                  placeholder="3000"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Accident Recovery Fee"
                  name="accidentRecoveryFee"
                  value={planData.accidentRecoveryFee}
                  onChange={handleChange}
                  error={!!errors.accidentRecoveryFee}
                  helperText={errors.accidentRecoveryFee}
                  placeholder="00"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  label="Ravine/Ditch/Precipice Fee"
                  name="precipiceFee"
                  value={planData.precipiceFee}
                  onChange={handleChange}
                  error={!!errors.precipiceFee}
                  helperText={errors.precipiceFee}
                  placeholder="00"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    mb: 2,
                    maxWidth: "300px",
                    fontFamily: "Montserrat",
                    "& .MuiOutlinedInput-root": {
                      height: "42px", // Adjust this value as needed to control height
                      fontSize: "0.875rem", // Adjusts text size within the input if desired
                      padding: "0 12px", // Controls padding (optional)
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& fieldset": {
                        borderColor: "#FDA214",
                      },
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FDA214",
                    },
                  }}
                />
              </Grid>
            </Grid>
            {/* Banner upload section */}

            <Box sx={{ mt: 3, mb: 3, width: "100%" }}>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                Uploaded Banners
              </Typography>

              <Box sx={{ width: "97%" }}>
                {bannerPreview || planData.bannerUrl ? (
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      paddingTop: "33.33%",
                      height: 0,
                      overflow: "hidden",
                    }}
                  >
                    <Box
                      component="img"
                      src={bannerPreview || planData.bannerUrl}
                      alt="Banner"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 1,
                      }}
                    />
                    <MuiButton
                      onClick={handleRemoveBanner}
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                        minWidth: "auto",
                        p: 0.5,
                        bgcolor: "white",
                        borderRadius: "50%",
                        "&:hover": { bgcolor: "gray.100" },
                      }}
                    >
                      <CloseIcon style={{ width: "1rem", height: "1rem" }} />
                    </MuiButton>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      border: "2px dashed #B8B8B8",
                      borderRadius: 2,
                      fontFamily: "Montserrat",
                      p: 4,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      minHeight: "100px",
                      transition: "background-color 0.2s",
                      "&:hover": {
                        backgroundColor: "rgba(253, 162, 20, 0.04)",
                      },
                    }}
                    onClick={() => fileInputRef.current?.click()}
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none", fontFamily: "Montserrat" }}
                      onChange={handleBannerUpload}
                      accept="image/jpeg,image/png,image/jpg"
                    />
                    <UploadIcon
                      style={{
                        width: "3rem",
                        height: "3rem",
                        fontFamily: "Montserrat",
                      }}
                    />
                    <Typography
                      sx={{ color: "#848484", fontFamily: "Montserrat" }}
                    >
                      Upload Banner (JPG, PNG or JPEG)
                    </Typography>
                    {errors.banner && (
                      <Typography
                        sx={{ mt: 2, color: "red", fontFamily: "Montserrat" }}
                      >
                        {errors.banner}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 3,
                gap: 2,
                fontFamily: "Montserrat",
              }}
            >
              <MuiButton
                type="submit"
                variant="contained"
                sx={{
                  bgcolor: "#FDA214",
                  color: "white",
                  fontFamily: "Montserrat",
                  "&:hover": {
                    bgcolor: "#FB923C",
                  },
                  width: "100px",
                }}
                disabled={!isFormValid()}
              >
                {editingPlan ? "Update" : "Create"}
              </MuiButton>
              <MuiButton
                onClick={handleCloseModal}
                variant="outlined"
                sx={{
                  background: "white",
                  fontFamily: "Montserrat",
                  color: "#F78409",
                  borderColor: "#F78409",
                  width: "120px",
                  height: "48px",
                  borderRadius: "24px",
                  border: "2px solid #F78409",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: "medium",
                  boxShadow: "none",
                }}
              >
                Cancel
              </MuiButton>
            </Box>
          </form>
        </Box>
      </Modal>
      {/* Delete Confirmation Modal */}
      <Dialog
        open={deleteModalOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-confirmation-dialog-title"
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "24px",
            maxWidth: "400px",
            fontFamily: "Montserrat",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            fontFamily: "Montserrat",
          }}
        >
          <MuiButton
            onClick={handleDeleteCancel}
            sx={{
              minWidth: "auto",
              fontFamily: "Montserrat",
              p: 0,
              color: "#FDA214",
              fontWeight: "bold",
              "&:hover": {
                bgcolor: "#FB923C",
                color: "white",
              },
            }}
          >
            <X />
          </MuiButton>
        </Box>
        <DialogTitle
          id="delete-confirmation-dialog-title"
          style={{
            fontWeight: "bold",
            fontFamily: "Montserrat",
            color: "#2C3E50",
            textAlign: "center",
            paddingBottom: "8px",
          }}
        >
          {planToDelete ? ` ${planToDelete.planName}` : ""}
        </DialogTitle>
        <DialogContent>
          <p
            style={{
              textAlign: "center",
              color: "#345C72",
              marginBottom: "24px",
              fontFamily: "Montserrat",
            }}
          >
            Are you sure you want to delete this plan?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontFamily: "Montserrat",
            }}
          >
            <MuiButton
              onClick={handleDeleteConfirm}
              variant="contained"
              style={{
                backgroundColor: "#FDA214",
                color: "white",
                borderRadius: "20px",
                padding: "10px 24px",
                textTransform: "none",
                fontWeight: "bold",
                fontFamily: "Montserrat",
              }}
            >
              Delete
            </MuiButton>
            <MuiButton
              onClick={handleDeleteCancel}
              variant="outlined"
              sx={{
                color: "#FDA214",
                borderColor: "#FDA214",
                borderRadius: "20px",
                padding: "10px 24px",
                textTransform: "none",
                fontFamily: "Montserrat",
                "&:hover": {
                  backgroundColor: "transparent", // Removes background color on hover
                  borderColor: "#FDA214", // Keeps border color the same
                },
              }}
            >
              Cancel
            </MuiButton>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Subscriptions;
